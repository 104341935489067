<template>
    <div class="content">
          <h1 style="text-align: center;">{{obj.title}}</h1>
          <div style="margin:auto" v-html="obj.content"></div>
    </div>
  </template>
  
  <script>
  import {getAfterSales} from '@/api/main.js'
  export default{
    data(){
        return {
          obj:{}
        }
    },
    mounted() {
      this.getAfterSales()
    },
    methods:{
      getAfterSales(){
        // 1维修作业流程、2维修收费规则、3客户维修须知
        getAfterSales({id:1}).then(res=>{
                if(res.code==0){
                    if(this.isChinese()){
                       this.obj = res.data
                    }else{
                        this.obj = {
                            ...res.data,
                            title:res.data.titleEng,
                            content:res.data.contentEng
                        }
                    }
                }else{
                    this.showMessage();
                }
            })
        }
    
    }
  }
  </script>
  <style lang="scss" scoped>
  .content{
    width: 1200px;
    margin: auto;
    padding: 48px 0px;
    h1{
        margin-bottom: 30px;
    }
  }
  </style>